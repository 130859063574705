import {createRouter, createWebHashHistory} from 'vue-router'

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/login',
            component: () => import("../pages/login/index.vue")
        },
        {
            path: '/',
            redirect: '/welcome',
            component: () => import("../pages/index/index.vue"),
            children: [
                {
                    path: '/welcome',
                    component: () => import("../pages/welcome/index.vue")
                },
                {
                    path: '/categories',
                    component: () => import("../pages/categories/index.vue")
                },
                {
                    path: '/users',
                    component: () => import("../pages/users/index.vue")
                },
                {
                    path: '/resources',
                    component: () => import("../pages/resources/index.vue")
                },
                {
                    path: '/resources-review',
                    component: () => import("../pages/resources-review/index.vue")
                },
                {
                    path: '/cash',
                    component: () => import("../pages/cash/index.vue")
                },
                {
                    path: '/messages',
                    component: () => import("../pages/messages/index.vue")
                }
            ]
        }
    ]
})