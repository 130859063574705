import {createApp} from 'vue'
import './style.scss'
import App from './App.vue'
import router from "./router";
import 'element-plus/dist/index.css'
import axios from "axios";

axios.defaults.baseURL = '/api'



createApp(App).use(router)
    .mount('#app')
