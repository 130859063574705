<template>
  <div class="h-full w-full">
    <router-view/>
  </div>
</template>
<script setup lang="ts">
</script>

<style scoped lang="scss">

</style>
